import React from 'react'
import styled from 'styled-components'
import Details from '../components/content/Details'
import earth from '../images/icons/earth.svg'
import exchange from '../images/icons/bars.svg'
import folder from '../images/icons/folder.svg'
import Title from '../components/typography/Title'
// import diretorLogoPng from '../images/logos/diretor-home-resized.png'
// import diretorLogoWebp from '../images/logos/diretor-home-resized.webp'
// import jklMp4 from '../videos/jkl-f.mp4'
// import jklJpg from '../videos/jkl-f.jpg'
// import jklDroneJpg from '../videos/jkl-drone.jpg'

function Home() {
  return (
    <>
      <div
        style={{
          position: 'relative',
          // marginTop: '-100px',
          overflow: 'hidden',
        }}
      >
        <video
          playsInline
          autoPlay
          muted
          loop
          poster="https://diretor-web.s3.eu-central-1.amazonaws.com/jkl-c.jpg"
          style={{
            position: 'relative',
            maxWidth: '100%',
            objectFit: 'cover',
            width: '100%',
            height: '600px',
            maxHeight: 'calc(100vh - 200px)',
            objectPosition: 'bottom',
          }}
        >
          <source src="https://diretor-web.s3.eu-central-1.amazonaws.com/jkl-c.webm" type="video/webm" />
          <source src="https://diretor-web.s3.eu-central-1.amazonaws.com/jkl-c.mp4" type="video/mp4" />
        </video>
        {/* <picture>
          <source type="image/webp" srcSet="https://diretor-web.s3.eu-central-1.amazonaws.com/diretor-home-resized.webp" />
          <img
            src="https://diretor-web.s3.eu-central-1.amazonaws.com/diretor-home-resized.png"
            alt=""
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              height: 'auto',
              width: '500px',
              maxWidth: '100%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </picture> */}
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        <Item>
          <Details
            title="Meistä"
            content={(
              <div style={{ textAlign: 'center' }}>
                <strong>Diretor Oy </strong>
                ja
                <strong> Innovest Kiinteistöt Oy </strong>
                ovat kiinteistösijoitusyhtiömme, jotka ostavat,
                vuokraavat ja myyvät kiinteistöjä ympäri Suomen,
                pienistä kerrostaloasunnoista aina suurempiin kokonaisuuksiin.
                <br />
                <br />
                Mikäli sinulla on tarjota kehityskohteita tai
                haluat myydä isomman kokonaisuuden, ole yhteydessä meihin.
              </div>
            )}
          />
        </Item>

        <Item>
          <Details
            title="Sijoittajalle"
            content={(
              <div style={{ textAlign: 'center' }}>
                Mikäli etsit uusia kohteita tai olet kiinnostunut asuntosijoittamisesta,
                ota meihin yhteyttä puhelimitse,
                sähköpostitse tai jätä yhteystietosi yhteydenottolomakkeella.
                <br />
                <br />
                Uusia kohteita on tarjolla jatkuvasti,
                ota yhteyttä ja kerromme mielellämme lisää.
              </div>
            )}
          />
        </Item>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        <Card>
          <div style={{ textAlign: 'center' }}>
            <div style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '16px',
            }}
            >
              <img style={{ height: '48px', marginRight: '16px' }} src={earth} alt="" />
              <Title>
                Sijainti
              </Title>
            </div>

            <p>
              Ostamme kokonaisuuksia koko maasta keskittyen
              erityisesti kasvukeskuksiin ja muuttovoittoisiin alueisiin,
              joissa vuokrattavuus on helppoa.
            </p>
          </div>
        </Card>
        <Card>
          <div style={{ textAlign: 'center' }}>
            <div style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '16px',
            }}
            >
              <img style={{ height: '48px', marginRight: '16px' }} src={exchange} alt="" />
              <Title>
                Tuotto
              </Title>
            </div>

            <p>
              Pystymme tarjoamaan asuntosijoittajille kohteita hyvällä
              vuokratuottoprosentilla ja korkealla sijoitetun pääoman tuotolla.
            </p>
          </div>
        </Card>
        <Card>
          <div style={{ textAlign: 'center' }}>

            <div>
              <div style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '16px',
              }}
              >
                <img style={{ height: '48px', marginRight: '16px' }} src={folder} alt="" />
                <Title>
                  Luotettava
                </Title>
              </div>

              <p>
                Kerromme ostajille avoimesti kohteen tiedot
                ja hoidamme sekä osto- että myyntiprosessin  reilusti ja läpinäkyvästi.
              </p>
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}

export default Home

const Item = styled.div`
  width: 400px;
  max-width: 100%;
`

const Card = styled.div`
  display: flex;
  margin: 24px;
  max-width: 300px;
  padding: 24px;
  box-shadow: 0 4px 8px 2px rgba(0,0,0,0.2);
  transition: 0.3s;
  &:hover {
    box-shadow: 0 8px 16px 2px rgba(0,0,0,0.2);
  }
`
